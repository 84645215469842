<template>
  <Layout>
    <PageHeader :title="title" :items="items"> </PageHeader>
    <div class="row" id="page">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-6">
                <div class="form-inline navbar-search">
                  <div class="input-group">
                    <input
                      name="title"
                      class="form-control bg-light border-0 small"
                      placeholder="Search Question"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      v-model="filter"
                      v-on:keyup="search"
                    />
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="searchFor">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="d-flex justify-content-end align-items-center"
                  v-if="can('add-survey')"
                >
                  <svg
                    class="svg-inline--fa fa-table mx-2"
                    height="16px"
                    width="16px"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fa"
                    data-icon="table"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z"
                    ></path>
                  </svg>
                  <router-link
                    :to="{
                      name: 'add-survey-question',
                      query: { survey_id: $route.params.id },
                    }"
                    class="opt"
                    >Add Survey Question</router-link
                  >
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table
                v-if="tableData.response"
                striped
                bordered
                :items="tableData.response"
                :fields="fields"
                :sort-by.sync="sortBy"
                :per-page="0"
                :current-page="currentPage"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive="sm"
                class="table-bordered table-hover"
                :key="key"
              >
                <template v-slot:cell(options)="row">
                  <div v-if="row.item.options" class="w-400">
                    <ul
                      v-for="(item, index) in row.item.options"
                      :key="index"
                      class="p-0"
                    >
                      <li class="m-1 survey-quest">{{ item.value }}</li>
                    </ul>
                  </div>
                </template>
                <template v-slot:cell(question)="row">
                  <span class="w-400">{{ row.item.question }}</span>
                </template>
                <template v-can="'edit-survey'" v-slot:cell(edit)="row">
                  <router-link
                    :to="{
                      name: 'edit-survey-question',
                      params: { id: row.item.id },
                      query: { survey_id: $route.params.id },
                    }"
                  >
                    <i class="fa fa-edit edit-i"></i>
                  </router-link>
                </template>
                <template
                  v-can="'update-survey-status'"
                  v-slot:cell(is_active)="row"
                >
                  <b-form-checkbox
                    switch
                    class="mb-1"
                    @change="updateStatus(row.item.id)"
                    :checked="row.item.is_active == 1 ? true : false"
                  >
                  </b-form-checkbox>
                </template>
                <template v-slot:cell(delete)="row" v-can="'delete-survey'">
                  <div @click.prevent="deleteSurveyQuestion(row.item.id)">
                    <i class="mdi mdi-trash-can delete-i"></i>
                  </div>
                </template>
              </b-table>
              <div
                class="d-flex justify-content-center my-5"
                v-if="tableData?.response?.length == 0"
              >
                <div style="display: grid">
                  <img src="../../../../public/noData.svg" alt="" />
                  <span class="no_content_text text-center"
                    >No Question Available</span
                  >
                </div>
              </div>
            </div>
            <div class="data_paginate" v-if="tableData.total_pages > 1">
              <div
                class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <b-pagination
                    v-model="currentPage"
                    first-number
                    last-number
                    :total-rows="tableData.total"
                    :total-pages="tableData.total_pages"
                    :per-page="tableData.per_page"
                  ></b-pagination>
                </ul>
              </div>
              <div class="form-inline navbar-search">
                <span class="mr-2">Go to a Particular Paginated Page :</span>
                <div class="input-group">
                  <b-form-input
                    id="case"
                    v-model="pageNo"
                    placeholder="Enter Page No"
                    class="in-width"
                  >
                  </b-form-input>
                  <div class="input-group-append">
                    <button class="btn btn-search" @click="filterPage">
                      <i class="fas fa-search fa-sm"> </i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import pageMixin from "../../../mixins/ModuleJs/survey-question-list";

export default {
  mixins: [MixinRequest, pageMixin],
  data() {
    return {
      title: "Survey Question List",
      items: [
        {
          text: "Back",
          href: "/survey",
        },

        {
          text: "List",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  created() {
    this.fetchData();
  },
};
</script>
